//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskJump } from '@/api/modular/flowable/handleTaskManage'
import { flowableDefinitionUserTasks } from '@/api/modular/flowable/defenitionManage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      columns: [
        {
          title: '節點名稱',
          dataIndex: 'name'
        }
      ],
      loadData: [],
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      tableLoading: false,
      selectedRowKeys: [],
      selectedRows: [],
      recordData: {},
      form: this.$form.createForm(this)
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.recordData = record
      this.visible = true
      this.flowableDefinitionUserTasks()
    },
    flowableDefinitionUserTasks () {
      this.tableLoading = true
      flowableDefinitionUserTasks({ id: this.recordData.procIns.procDef.id }).then((res) => {
        this.tableLoading = false
        this.loadData = res.data
      })
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      if (this.selectedRows.length < 1) {
        this.$message.warning('請選擇跳轉節點')
        return
      }
      validateFields((errors, values) => {
        if (!errors) {
          values.taskId = this.recordData.id
          values.targetActId = this.selectedRows[0].id
          values.targetActName = this.selectedRows[0].name
          this.confirmLoading = true
          handleTaskJump(values).then((res) => {
            if (res.success) {
              this.$message.success('跳轉成功')
              this.returnPage()
            } else {
              this.$message.error('跳轉失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    /**
     * 節點列表選擇
     */
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage () {
      this.$emit('close')
      this.handleCancel()
    },
    /**
     * 關閉
     */
    handleCancel () {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.form.resetFields()
      this.visible = false
    }
  }
}
